import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import { Heading, P } from "../../../packages/Typography/src";
import Textfield from "../../../packages/Textfield/src";
import Button from "../../../packages/Button/src";
import Flex from "../../../packages/Flex/src";

var HomeBannerPattern = function HomeBannerPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Div, {
    bg: "grey.50"
  }, React.createElement(Container, {
    size: "xs",
    py: "5rem"
  }, React.createElement(Heading, {
    as: "h1",
    textAlign: "center",
    mt: "0"
  }, "Understand the world"), React.createElement(P, {
    size: "lg",
    muted: true,
    textAlign: "center"
  }, "Sign up for our newsletter to get the latest charts and stories."), React.createElement(Flex, {
    justifyContent: "center",
    flexWrap: "wrap"
  }, React.createElement(Div, {
    width: ["100%", "50%"],
    flex: "0 1 auto"
  }, React.createElement(Textfield, {
    width: "100%",
    type: "email",
    placeholder: "Your email",
    size: "lg",
    borderRadius: "pill"
  })), React.createElement(Div, {
    width: ["100%", "auto"],
    flex: "none",
    mt: ["0.5rem", "0"],
    ml: ["0", "0.5rem"]
  }, React.createElement(Button, {
    width: "100%",
    variant: "primary",
    size: "lg",
    borderRadius: "pill"
  }, "Subscribe"))))));
};

export default HomeBannerPattern;